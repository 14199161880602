








































































































































































































































import { Mixin_list } from "@/mixin";
// import { api_city, city } from "@/http/city";
import { api_goods, goods } from "@/http/goods";
import { api_user } from "@/http/user";
import { api_order } from "@/http/order";
import { Component } from "vue-property-decorator";
import Page from "@/components/page/index.vue";
import Table from "@/components/table/index.vue";
import DatePicker from "@/components/datePicker/index.vue";
import StorageDb from "@/util/storage";
import { DTPWeb, LPA_JobNames } from "dtpweb";
import { ElForm } from "element-ui/types/form";

@Component({
  components: {
    Page,
    Table,
    DatePicker,
  },
})
export default class extends Mixin_list<goods>(api_goods.get_list) {
  filter = {
    update_time_after: "",
    update_time_before: "",
    name: "",
    id: "",
  };
  resetFilter = {
    ...this.filter,
  };
  columns = [
    {
      label: "信息",
      slot: "info",
      prop: "info",
      width: 300,
    },
    {
      label: "价格（元）",
      prop: "price",
      width: 200,
    },
    {
      label: "已售/库存",
      slot: "salestock",
      prop: "salestock",
      width: 300,
    },
    {
      label: "状态",
      prop: "status",
      slot: "status",
      width: 120,
    },
    {
      label: "上架时间",
      prop: "update_time",
      width: 300,
    },
    {
      label: "操作",
      prop: "",
      slot: "operation",
    },
  ];
  getImageUrl(cover: string) {
    if (cover && typeof cover === "string") {
      // 将字符串按逗号分割成数组
      const coverIds = cover.split(",");
      // 取数组的第一个元素作为图片ID
      const coverId = coverIds[0].trim();
      // 拼接图片URL
      return `https://images.xiyuebiaoju.com/${coverId}`;
    }
  }
  status(status: number) {
    switch (status) {
      case 1:
        return "已上架";
      case 0:
        return "已下架";
    }
  }
  address(data: any) {
    return `${data.province}${data.city}${data.region}${data.address}`;
  }
  onUpdateRange([startStr, endStr]: [string, string]) {
    this.filter.update_time_after = startStr;
    this.filter.update_time_before = endStr;
  }
  reset() {
    this.filter = { ...this.filter, ...this.resetFilter };
    (this.$refs.datePicker as Vue & { clearDates: () => void }).clearDates();
    this.find();
  }

  async handleClick(type: string, row: any) {
    switch (type) {
      case "status":
        await api_goods.edit({ ...row, status: row.status === 0 ? 1 : 0 });
        this.get_list();
        return;
    }
  }

  created() {
    // this.get_city_list();
  }
  click_cell(row: goods) {
    return this.$router.push(`/goods/edit/?id=${row.id}`);
  }
  async del(id: number) {
    await api_goods.del_one(id);
    this.get_list();
  }
  dialogVisible = false;
  phone = "";
  handleClose(done: any) {
    this.$confirm("确认关闭？")
      .then((_) => {
        this.phone = "";
        this.user_info = {};
        done();
      })
      .catch((_) => {});
  }

  // 打开弹框，存储信息
  goods_id: number = 0;
  isVip: boolean = false;
  skus: any[] = []; // 商品规格
  currentSkuKey: string = ""; // 当前选中的规格
  currentSku: any = {}
  openDialog(row: any) {
    // console.log(row)
    this.goods_id = row.id;

    if (typeof row.sku === "string") {
      // 尝试解析 JSON 字符串
      try {
        this.skus = JSON.parse(row.sku) as any[];
        this.currentSkuKey = this.skus[0]['key'];
        this.currentSku = this.skus[0];
      } catch (error) {
        console.error("Error parsing JSON:", error);
        // 如果解析失败，可以设定一个默认值或处理错误
        this.skus = [];
      }
    }
    this.$confirm("是否为会员用户", "提示", {
      confirmButtonText: "是",
      cancelButtonText: "不是",
      type: "warning",
    })
      .then(() => {
        this.isVip = true;
        this.dialogVisible = true;
      })
      .catch(() => {
        this.isVip = false;
        this.dialogVisible = true;
      });
    //
  }
  // 搜索会员信息
  // 会员信息
  user_info: any = {};
  onSearch(event: any) {
    api_user.get_user_info({ phone: this.form.phone }).then((res: any) => {
      this.user_info = res.results[0];
      if (res.results.length == 0) {
        this.$message.error("暂无此会员信息");
        return;
      }
    });
  }
  getLevel(level: number) {
    switch (level) {
      case 1:
        return "非会员";
      case 2:
        return "黄金会员";
      case 3:
        return "钻石会员";
      default:
        return "暂无信息";
    }
  }
  // 二维码
  qrcode_url = "";
  // 小程序码
  appletcode = "";
  // 下单数量
  form = {
    number: 1,
    phone: "",
  };
  rules = {
    number: [
      { required: true, message: "请填写购买数量", trigger: "blur" },
      { validator: this.validateNumberRule, trigger: "change" },
    ],
    phone: [
      { required: true, message: "请填写手机号码", trigger: "blur" },
      {
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入有效的手机号码",
        trigger: ["blur", "change"],
      },
    ],
  };
  validateNumberRule(rule: any, value: any, callback: any) {
    if (value < 1) {
      callback(new Error("购买数量必须大于0"));
    } else {
      callback();
    }
  }
  // 确定下单
  async submitOrder(isVip: any) {
    const that = this;
    await (this.$refs["form"] as ElForm | undefined)?.validate();
    // if (!that.validatePhone()) {
    //   return;
    // }
    // debugger
    if (isVip && that.user_info.id == undefined) {
      return that.$message.error("请先搜索会员信息");
    }
    if(this.form.number >this.currentSku.stock){
      return that.$message.error("库存不足");
    }
    await api_order
      .add_order({
        goods: that.goods_id,
        store: StorageDb.getLocal("store_id"),
        user: isVip == true ? that.user_info.id : 0,
        need: that.user_info.wallet <= 0 || isVip == false ? 0 : 1,
        number:that.form.number,
        phone: isVip == true ? "" : that.form.phone,
        sku_key:that.currentSkuKey
      })
      .then((res: any) => {
        that.form = {
          number: 1,
          phone: "",
        };
        that.dialogVisible = false;
        that.qrcode_url = `https://images.xiyuebiaoju.com/${res.qrcode}`;
        that.appletcode = `https://images.xiyuebiaoju.com/${res.appletcode}`;
        if (that.user_info.wallet <= 0) {
          that.paymentDialogVisible = true;
          that.user_info = {};
        } else {
          // 直接打印二维码
          that.onDrawImageFile();
          that.user_info = {};
        }
      });
  }
  // 选择sku
  selectSku(sku:any){
    this.currentSku = sku;
    this.currentSkuKey = sku.key;
  }
  // 付款二维码弹框
  paymentDialogVisible = false;

  // 关闭付款二维码弹框
  closePaymentDialog() {
    this.paymentDialogVisible = false;
  }
  api: any = "";
  printers = [{ name: "未检测到打印机", value: {} }];
  currPrinter = { name: "" };
  mounted() {
    DTPWeb.checkServer({
      downloadUrl: "https://weida.dothantech.com/beta/assets/dtpweb/index.html",
      callback: (api, server) => {
        this.api = api;
        console.log(`---- ---- check server ---- ----`);
        console.log(server);
        if (api) {
          this.updatePrinterList();
        } else {
          this.printers[0].name = "未检测到打印插件";
        }
      },
    });
  }
  /**
   * 更新打印机列表。
   */
  updatePrinterList() {
    if (!this.api) return;
    //
    const api = this.api;
    //
    const values = api.getPrinters();
    // 清空旧打印机列表
    this.printers.splice(0);
    //
    if (values.length > 0) {
      for (const item of values) {
        const title = api.isLocalPrinter(item)
          ? item.name
          : `${item.name}@${item.ip}`;
        this.printers.push({
          name: title,
          value: item,
        });
      }
      //
      this.currPrinter = values[0];
    } else {
      this.printers.push({ name: "未检测到打印机", value: "" });
    }
  }
  currJobName = LPA_JobNames.Preview;
  currOrientation = 0;
  isPrintJob() {
    return (
      this.currJobName !== LPA_JobNames.Preview &&
      this.currJobName !== LPA_JobNames.Transparent
    );
  }
  openPrinter(callback: any) {
    if (this.api) {
      this.api.openPrinter(this.currPrinter, callback);
    } else if (callback) {
      callback(false);
    }
  }
  /**
   * 绘制图片URL测试
   */
  onDrawImageFile() {
    this.openPrinter((success: any) => {
      if (!this.isPrintJob() || success) {
        const width = 35;
        const height = 30;
        const orientation = this.currOrientation;
        const jobName = this.currJobName;
        const margin = 0;
        const url = this.qrcode_url;
        const codeWidth = width - margin * 2;
        if (this.api.startJob({ width, height, orientation, jobName })) {
          this.api.drawImage({
            imageFile: url,
            x: -3,
            width: width,
            height: height,
          });
          this.api.commitJob((success: any) => {
            if (success) {
              const info = this.api.getPageInfo();
              if (info) {
                const page = this.api.getPageImage({ page: 0 });
                this.api.printImage({
                  data: page.data,
                });
              }
            }
          });
        }
      }
    });
  }
  /**
   * 直接打印BASE64图片测试
   */
  onPrintImageData() {
    /**
     * @type {HTMLElement}
     */
    const datas: any = this.$refs["preview-list"];
    if (datas && datas.childNodes) {
      const childNodes = datas.childNodes;
      for (let i = 0; i < childNodes.length; i++) {
        if (childNodes[i].src) {
          this.api.printImage({
            data: childNodes[i].src,
            printerName: this.currPrinter.name,
          });
        }
      }
    }
  }
  submit() {
    this.closePaymentDialog();
    // 已付款，打印二维码
    this.onDrawImageFile();
  }
}
